* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Courier New", Courier, monospace;
  -webkit-font-smoothing: antialiased;
  // text-rendering: geometricPrecision;
  // -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.app {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: black;
  color: white;
  display: grid;
  grid-template-rows: 5rem minmax(0, 1fr) 4rem;
  grid-template-columns: 450px minmax(0, 1fr);
  grid-template-areas:
    "header header"
    "sidebar main"
    "footer footer";
}

.mobile__toggle {
  display: none;
}

/* =============== HEADER =============== */
header {
  grid-area: header;
  border-bottom: 1px solid white;
  h1 {
    font-size: 2rem;
    text-align: center;
  }
}

/* =============== ASIDE =============== */
aside {
  grid-area: sidebar;
  border-right: 1px solid white;
  padding: 2rem;
  overflow: auto;
  p {
    font-size: 1.3rem;
    font-weight: 400;
  }
}

/* =============== FOOTER =============== */
footer {
  border-top: 1px solid white;
  grid-area: footer;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: black;
  a {
    color: inherit;
  }
}

/* =============== MAIN =============== */
main {
  border-top: 1px solid white;
  grid-area: main;
  display: flex;
  flex-direction: row;
  &.split {
    .info,
    .map {
      width: 50%;
    }
  }
  .info {
    width: 0%;
    height: 100%;
    transition: 0.2s all ease;
  }
  .map {
    width: 100%;
    height: 100%;
    transition: 0.2s all ease;
  }
}

/* =============== MOBILE =============== */

@media screen and (max-width: 1024px) {
  html {
    font-size: 14px;
  }

  body {
    height: auto;
    overflow: auto;
  }

  .app {
    width: 100vw;
    height: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  aside {
    border-right: none;
    padding: 2rem 2rem 1rem 2rem;
    .toggle {
      width: 80%;
      max-width: 20rem;
      margin: 0 auto;
      margin-top: 4rem;
      display: flex;
      --width: 10rem;
      --height: 5rem;
      --shadow: 0.8;
      --skew: 7;
      --cut-height: 15px;
      --cut-width: 10px;
    }
  }

  main {
    height: calc(100vh - 11rem);
    display: flex;
    flex-direction: row;
    border-right: none;
    &.split {
      .info {
        width: 70%;
        border-left: 1px solid white;
      }
      .map {
        width: 30%;
      }
    }
    .info {
      border: none;
    }
  }

  footer {
    height: 4rem;
    p {
      font-size: 0.8rem;
    }
  }
}
