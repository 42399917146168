/* =============== LOCATION =============== */
.info {
  border-left: 1px solid white;
  position: relative;
  overflow-y: scroll;
}

.location {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  // z-index: -1;
  // background-color: black;
  // visibility: none;
  &.-active {
    display: block;
    // z-index: 1;
  }
}

.location__header {
  border-bottom: 1px solid white;
  height: 4rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  position: sticky;
  top: 0;
  background-color: black;
  z-index: 1;

  h2 {
    padding: 0;
    margin: 0;
    text-transform: capitalize;
  }
}

.location__content {
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: auto;
    display: block;
    border: none;
  }
}

.location__text {
  padding: 2rem;
}

.close__button {
  height: 100%;
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  background-color: black;
  cursor: pointer;

  svg {
    height: 100%;
    width: auto;
  }

  .icon__close path {
    fill: white;
  }
  &:hover {
    background-color: white;
    .icon__close path {
      fill: black;
    }
  }
}

@media screen and (max-width: 1024px) {
  .location__text {
    padding: 1.5rem;
  }
  .location__header {
    padding-left: 1.5rem;
    height: 3rem;
  }
  .close__button {
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-top: 0px solid white;
    border-bottom: 0px solid white;
  }
}
