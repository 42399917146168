/* =============== MAP =============== */
.map {
  height: 100%;
}

.mapboxgl-control-container {
  display: none;
}

.marker {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-image: radial-gradient(
    circle at center,
    yellow 0,
    yellow 10%,
    transparent 10%,
    transparent 20%,
    yellow 20%,
    yellow 28%,
    transparent 28%,
    transparent 40%,
    yellow 40%,
    yellow 48%,
    transparent 46%,
    transparent 60%,
    yellow 60%,
    yellow 64%,
    transparent 64%,
    transparent 80%,
    yellow 80%,
    yellow 82%,
    transparent 82%,
    transparent 100%
  );

  &:hover {
    width: 2.5rem;
    height: 2.5rem;
  }

  &:active {
    filter: hue-rotate(30deg);
  }

  &.-active {
    animation: rotateColor 1s infinite;
  }
}

.markerTemplates {
  display: none;
}

@keyframes rotateColor {
  from {
    filter: hue-rotate(30deg);
  }

  to {
    filter: hue-rotate(389deg);
  }
}
