.toggle {
  --width: 15rem;
  --height: 8rem;
  --shadow: 0.8;
  --skew: 7;
  --cut-height: 20px;
  --cut-width: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}

.toggle__button {
  width: var(--width);
  height: var(--height);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
  z-index: 1;
  &::before {
    content: " ";
    position: absolute;
    bottom: -2px;
    left: -2px;
    width: 0px;
    height: 0px;
    border-bottom: var(--cut-height) solid black;
    border-right: var(--cut-width) solid transparent;
    border-left: none;
    z-index: -1;
  }
  
  &::after {
    content: " ";
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 0px;
    height: 0px;
    border-bottom: var(--cut-height) solid black;
    border-left: var(--cut-width) solid transparent;
    border-right: none;
    z-index: -1;
  }
}

.toggle__part {
  width: 50%;
  height: calc(var(--shadow) * var(--height));
  border: 1px solid white;
  background-color: black;

  &.-left {
    border-right: none;
    transform-origin: right center;
    transform: skewY(0deg);
    transition: all 0.05s;
    box-shadow: -20px 20px 0 20px white;
    position: relative;
  }

  &.-right {
    border-left: none;
    transform-origin: left center;
    transform: skewY(0deg);
    transition: all 0.05s;
    box-shadow: 20px 20px 0 20px white;
    position: relative;
  }
}

.toggle.-on {
  .toggle__button::before {
    z-index: 1;
  }
  .toggle__part.-left {
    transform: skewY(calc(1deg * var(--skew)));
  }
}

.toggle.-off {
  .toggle__button::after {
    z-index: 1;
  }
  .toggle__part.-right {
    transform: skewY(calc(-1deg * var(--skew)));
  }
}

.toggle__label {
  font-size: 1rem;
  &.-active {
    font-weight: bold;
  }
}
